import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import styles from "./header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header className={styles.header}>
      <h1>{data.site.siteMetadata.title}</h1>
      <nav>
        <ul className={styles.links}>
          <li>
            <Link className={styles.link} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className={styles.link} to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className={styles.link} to="/blog">
              Blog
            </Link>
          </li>
          <li>
            <Link className={styles.link} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}
export default Header
