import React from 'react'

import Header from './header'
import Footer from './footer'

import '../styles/layout.scss'
import styles from './layout.module.scss'

const Layout = ({children}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <Header />
      <main>
        {children}
      </main>        
    </div>
    <Footer />
  </div>
)

export default Layout