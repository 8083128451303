import React from 'react'

import Layout from '../components/layout'

const NotFound = () => (
  <Layout>
    <h1>Page not found</h1>
    <h3>Go somewhere else!</h3>
  </Layout>
)

export default NotFound